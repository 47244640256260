@import "core/_variables.scss";
@import "core/_fonts.scss";

body{
    margin:0;
    font-size:16px;
    font-family: "robotoregular";
    background-color: $white;
    @media only screen and (min-width:1920px){
        font-size: 18px !important;
    }
    @media only screen and (max-width:1024px){
        font-size: 14px !important;
    }
    @media only screen and (max-width:480px){
        font-size: 13px !important;
    }
}
p{font-size:1.1em;}

.mt-50{
    margin-top:50px;
    @media screen and (max-width:576px) {
        margin-top: 25px;
    }
}
.mb-30{
    margin-bottom: 30px;
}
.mb-0{
    margin-bottom: 0;
}
.mb-15{
    margin-bottom: 15px;
}
.pl-15{
    padding-left: 15px;
}
.pink{color:$pink;}
.orange{color:$orange;}
.blue{color:$blue;}
.brown{color:$brown;}
.bg_light_gray{
    background-color: rgb(238 227 227 / 54%);
    padding: 50px 0px;
    @media screen and (max-width:576px) {
        padding: 25px 0px;
    }
}
.bg_purple{
    background-color: purple;
}
.relative{
    position: relative;
}
.bold{
    font-family: 'robotobold';
}
.underline{
    border-bottom: 2px solid $primaryColor;
}
.primary_color{
    color: $primaryColor;
}

// main.home_page{
//     .header_wrapper{
//         background-position: right;
//     }
// }
.header_wrapper{
    // background: url('../images/top-banner.jpeg');
    // background-position: left;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background: rgb(121,82,179);
    // background: linear-gradient(58deg, rgba(121,82,179,1) 0%, rgba(115,68,188,1) 55%, rgba(144,236,255,1) 100%);
    background-color: #fff;
    // border-bottom: 1px solid $primaryColor;
    .navbar{
        background-color: transparent;
        box-shadow: none;
        border: 0;
        color:$white;
        border-bottom: 1px solid $primaryColor;
    }
}
.banner_sec{    
    // padding: 20px 15px 50px 15px;
    // h2{
    //     font-size: 2.8125em;
    //     text-transform: capitalize;
    //     text-align: center;
    //     color: $primaryColor;
    //     margin-bottom: 30px;
    // }
    // .banner_slider{
    //     max-width: 55%;
    //     margin: 0 auto;
    //     border:2px solid $primaryColor;
    //     @media screen and (max-width:769px) {
    //         max-width: 100%;
    //     }
    // }   
    .popout_images{
        // position: relative;
        display: flex;
        -moz-display: flex;
        -ms-display: flex;
        -o-display: flex;
        -webkit-display: flex;
        align-self: center;
        // height: 300px;
        @media screen and (max-width:769px) {
            display: none;
            -moz-display: none;
            -ms-display: none;
            -o-display: none;
            -webkit-display: none;
        }
        img, video{
            position: absolute;
            bottom: 0;
            // height: 230px;
            width:20%;
            height: auto;
            border: 4px solid #fff;
            border-radius: 10px;
            // width: auto;
            &.left{
                left:0;
            }
            &.right{
                right:0;
            }
        }
    }
}

.banner_slider{
    .carousel-indicators{
        // bottom: -50px;
        display: none;
        button{
            background-color: $primaryColor !important;
            width: 40px;
            height: 10px;
        }
    }
    &.cpa_banner{
        border-bottom: 2px solid $primaryColor;
        border-top: 2px solid $primaryColor;
        .carousel-indicators{
            bottom: 0px !important;
        }
        .carousel-control-prev, .carousel-control-next{
            display: none;
        }
    }
    @media screen and (max-width:769px){
        .carousel-item{
            img{
                min-height: 250px;
                width: 100% !important;
            }
        }
    }
}
.pdf_div{
    background-color: gray;
    padding: 20px;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    border-radius: 20px;
    a{
        color: #fff;
        text-decoration: none;
        font-family: 'robotobold';
        font-size: 1.1em;
        img{
            width: 100px;
            height: auto;
            margin-top: 20px;
        }
    }
}

.topBottom{
    animation: topbottom 5s ease infinite alternate;
    -webkit-animation: topbottom 5s ease infinite alternate;
    -moz-animation: topbottom 5s ease infinite alternate;
    -ms-animation: topbottom 5s ease infinite alternate;
    -o-animation: topbottom 5s ease infinite alternate;
}
@keyframes topbottom
{
  0% {
    bottom: 0%;
  }
  100% {
    bottom: 25%;
  }
}
.flex_component{
    display: flex;
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    justify-content: center;
}

.title{
    font-size: 2.1875em;
    font-family: 'robotobold';
    color: $primaryColor;
    text-transform: capitalize;
}
.subtitle{
    text-transform: capitalize;
    font-size: 1.7em;
    color:$primaryColor;
    font-family: "robotobold";
    &.purple{
        color:$purple;
    }
}
.img_radius{
    border-radius: 20px;
}
.underline{
    border-bottom:3px solid $primaryColor;
    width: max-content;
}

.top_nav{
    background-color: $primaryColor;
    padding:10px 0px;
    color:$white;
    display: flex;
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    width: 100%;
    .top_left, .top_right, .top_center{
        display: flex;
        -webkit-display: flex;
        -moz-display: flex;
        -ms-display: flex;
        -o-display: flex;
        flex: 1 1 auto;
        align-items: center;
        ul{
            margin: 0;
            padding-left: 0px;
            li{
                list-style-type: none;
                display: inline-block;
                padding-right: 15px;                
            }
        }
    }
    .top_left{
        justify-content: flex-start;
    }
    .top_right{
        justify-content: flex-end;
        @media screen and (max-width:640px) {
            width: 70%;
        }
    }
    &.cpa_top{
        background-color: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        color:#000;
    }
}
.svg_img{
    width: 25px;
    height: 25px;
    @media screen and (max-width:640px) {
        width: 20px;
        height: 20px;
    }
}


.dropdown-item.active, .dropdown-item.focus, .dropdown-item.hover, .dropdown-item:active{
    background-color: transparent !important;
}
.navbar{
    // background-color: $white;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    .nav-link, .nav-item{
        // color: $white;
        color: $black;
        font-size: 1em;
        font-family: 'robotomedium';       
        &.active{
            color: $primaryColor !important;
        }
        &.show{
            .nav-link{
                color: $primaryColor !important; 
            }
        }
    }    
    &.sticky{
        position: fixed;
        background-color: $white;
        color: $black;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #cecece;
        -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);        
        animation: sticky 2s;
        -webkit-animation: sticky 2s;
        -moz-animation: sticky 2s;
        -ms-animation: sticky 2s;
        -o-animation: sticky 2s;
        .nav-link, .nav-item{
            color: $black;
            &.show{
                color: $primaryColor !important;
            }
        }
    }
    a{
        &:hover{
            color:$primaryColor;
        }
        &.active{
            background-color: transparent !important;
        }
    }
    .navbar-brand{
        img{
            width: 120px;
        }
    }
}

.breadcrumb_bg{
    background-image: url('../images/breadcrumb-bg.jpeg');
    background-repeat: repeat;
    text-align: center;
    padding: 100px 15px;
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width:576px){ 
        max-height: 200px;
        min-height: 200px;
    } 
    @media only screen and (max-width:769px){
        background-position: center;
    }
    .breadcrumb_content{
        font-family: 'robotobold';
        text-transform: capitalize;
        .breadcrumb{
            align-items: center;
            display: inline-flex;
            -webkit-display: inline-flex;
            -moz-display: inline-flex;
            -ms-display: inline-flex;
            -o-display: inline-flex;
            margin-bottom: 0px;
            .breadcrumb-item{
                a{
                    color: $primaryColor;
                }
            }
        }
    }
}


// .carousel{
//     .carousel-item{
//         img{
//             max-height: 500px;
//         }
//     }
// }

.about_card{
    align-items: center;
    .card{
        padding:30px;
        text-align: center;       
        width:150px;
        height: 150px;
        margin: 15px 15px 0px 15px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:hover{
            transform: translateY(-10px);
            -webkit-transform: translateY(-10px);
            -moz-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            -o-transform: translateY(-10px);
        }
        a{
            text-decoration: none;
            color:$white;
            margin:0px;
            img{
                width:50px;
            }
            p{
                padding-top: 10px;
            }
        }        
    }
    .card1{
        background-color: $pink;
    }
    .card2{
        background-color: $blue;
    }
    .card3{
        background-color: $primaryColor;
    }
    .card4{
        background-color: $brown;
    }    
}


.counter_sec{
    // background-color: $secondaryColor;
    // padding:35px;
    .container{
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        // grid-gap: 30px;
        text-align: center;
        .main_div{
            background-color: $primaryColor;
            padding: 30px 15px 30px;
            border-radius: 20px;
            -webkit-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
            -moz-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
            -ms-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
            -o-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
            box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
            color: #fff;
            .counter_item{
                text-align: center;
                position: relative;
                @media screen and (max-width:591px) {
                    border-bottom: 1px solid #fff;
                }
                &::before{
                    content: "";
                    position: absolute;
                    right: -15px;
                    top: 0;
                    width: 2px;
                    height: 108px;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    -o-border-radius: 2px;
                    -ms-border-radius: 2px;
                    border-radius: 2px;
                    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
                    @media screen and (max-width:591px) {
                        display: none;
                    }
                }                
            }
        }        
        .counter{
            // color: $black;
            font-size: 3em;
        }
        .counter_text{
            // color: $black;
            font-size: 1.125em;
            font-family: 'robotomedium';
        }
    }
}

.counter_sec .main_div .row [class*=col-]:last-child .counter_item::before {
    display: none;
  }
  .counter_sec .main_div .row [class*=col-]:last-child .counter_item {
    border-bottom: none;
  }

.border_right{
    border-right: 2px solid $black;
    padding: 0px 5px;
}

// slider css
.almuni_slider{    
    .slick-dots{
        position: relative !important;
    }
    .slick-slide{
        position: relative;
        padding: 15px;        
        .card{
            border-radius: 4px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            margin-top: 100px;
            .card-title{
                font-size: 1.125em;
            }
            .card-subtitle{
                color: $purple;
                margin-bottom: 15px;
            }
            .card-img{
                border:4px solid $white;
                height: 130px;
                width: 130px;
                display: block;
                margin: -58px auto 0 auto;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                img{
                    width:120px;
                    height: 120px;   
                    display: block;
                    margin: 0 auto;   
                    object-fit: cover;
  
                }
            }
        }
    }
}

.top_footer{
    // background-color: $gray;
    [class*="col"]{
        padding: 12px;
        position: relative;
        flex:14.28%;
        // transition: all .5s;
        @media screen  and (max-width:768px){
            flex:33.33%;
        }
    }
    a{
        text-decoration: none;  
        // transition: all .5s; 
        &:hover{
            background-color: rgb(222 226 230 / 62%);
            // transition: all .5s;
            text-decoration: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 12px;
        }     
    }
    img{
        width:50px;
        height: auto;
        margin: 0 auto;
        display: block;
        @media screen  and (max-width:768px){
            width: 35px;
        }
    }
    h3{
        font-size: 1.0625em;
        margin-top: 15px;
        text-align: center;
        color: $white;
    }
}

// footer css
.footer{
    // background-color: $secondaryColor;
    background: url('../images/footer-banner.jpeg');
    background-repeat: repeat;
    background-position: right;

    padding: 30px 0px;
    color:$black;
    .qrcode{
        width: 100%;
        height: auto;
    }
    .logo{
        width: 200px;
    }
    h4.head{
        margin-top: 10px;
        color:$primaryColor;
        font-family: 'robotobold';
    }
    .social_icon{
        margin-top:20px;
        display: flex;
        -webkit-display: flex;
        -moz-display: flex;
        -ms-display: flex;
        -o-display: flex;
        flex: 1 1 auto;
        align-items: center;
        ul{
            padding-left: 0px;
            margin-bottom: 5px;
            li{
                list-style-type: none;
                display: inline-block;
                padding-right: 15px;     
                background-color: $black;
                padding: 2px;
                margin: 5px;
                border-radius: 4px;
                border:1px solid $black;
                &:hover{
                    background-color: $primaryColor;
                    border:1px solid $primaryColor;
                }           
            }
        }
    }
    p{
        font-family: 'robotomedium';
    }
    .footer_heading{
        margin-bottom: 5px;
        color:$primaryColor;
        font-size: 1.125em;
        text-transform: uppercase;
        font-family: 'robotobold';
    }
    .footer_link{
        display: block;
        color: $black;
        text-decoration: none;
        font-family: 'robotomedium';
        text-transform: capitalize;
        &:hover{
            transition: padding .4s;
            padding-left: 10px;
            color: $primaryColor;
        }
    }
    .other_links{
        display: flex;
        justify-content: center;
        width: 100%;
        .footer_link{
            &::after{
                content: '';
                border-right:1px solid #000;
                border-width: 1px;
                margin: 0 15px;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
        }
    }
}

.bottom_footer{
    // background-color:rgb(121 82 179 / 50%);
    background-color: $primaryColor;
    color: $white;
    padding:15px 0px;
    font-family: 'robotomedium';
}

// about us css
.core_cards{
    display: flex;
    @media screen and (max-width:769px){
        flex-wrap: wrap;
        flex-direction: row;
    }
    .card{
        width: 100%;
        margin: 0px 10px;
        text-align: center;
        background-color: $white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        @media screen and (max-width:769px){
            flex: 33.33%;
            margin-bottom: 15px;
        }
        &:hover{
            transform: translateY(-10px);
        }
        .card-body{
            @media screen and (max-width:576px){ 
                padding:10px;
            } 
            img{
                display: block;
                margin: 0 auto;
                background-color: $primaryColor;
                border-radius: 100%;
                padding: 10px;
                margin-bottom: 20px; 
                @media screen and (max-width:576px){ 
                    width: 45px; 
                }             
            }
            .card-title{
                text-transform: capitalize;
                font-size: 1.125em;
                margin-bottom: 0px;
            }
        }
    }
}

.info-card{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .card-body{
        padding: 0;
        .card-title{
            text-align: center;
            text-transform: uppercase;
            color: $primaryColor;
            padding: 15px;
            font-family: 'robotobold';
            margin-bottom: 0px;
            @media screen and (max-width:1025px){
               padding: 10px 5px;
               font-size: 1em; 
            } 
        } 
        img{
            border-radius: 4px;
            height: 300px;
            width: 100%;
            object-fit: fill;
            @media screen and (max-width:1025px){
                height: 210px;
                object-fit: cover;
                object-position: top;
            }  
            @media screen and (max-width:576px){
                height: auto;
                object-fit: cover;
                object-position: top;
            }            
        }       
    }
    .hover-text{
        display: none;
        transition: all 0.8s ease;
        position: absolute;
        bottom: 0;
        background-color: rgb(255 255 255 / 92%);
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        padding: 50px 10px;
        border-radius: 4px;
        p{
            margin-bottom: 5px;
            font-family: 'robotomedium';
        }
        .card-title{
            text-transform: uppercase;
            color: $primaryColor;
            font-family: 'robotobold';
        }
    }
    &:hover{
        .hover-text{
            display: block;            
            transform: translateY(10);
        }
    }
}

.theme_btn{
    background-color: $black !important;
    border:1px solid $black !important;
    font-size: 1.125em !important;
    border-radius: 80px !important;
    padding: 7px 20px !important;
    color:$white;
    @media screen and (max-width: 768px){
        padding: 7px 12px !important;
    }
    &:hover{
        background-color: transparent !important;
        color:$black !important;
    }
}
.custom_list{
    padding-left: 10px;
    margin-bottom: 0px;
    li{
        padding-left: 3px;
        a{
            text-decoration: none;
            color:$black;
            &:hover{
                color:$primaryColor;
            }
        }
        &::marker{
            content:'>';
            color: $primaryColor;
            padding-right: '2px';
            font-family: 'robotobold';
        }
    }
}

.theme_tabs{
    li{
        .nav-link{
            border:0;
            color: $black;
            font-family: 'robotomedium';
            font-size: 1.1em;
            &:hover{
                color: $primaryColor;
            }
            &.active{
                color: $primaryColor;
                border-bottom: 2px solid;
            }
        }
    }
    .sub_tabs{
        background-color: $secondaryColor;
        padding: 15px;
    }
}
.download_list{
    padding-left: 10px;
    margin-bottom: 0;
    li{
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 5px;
        a{
            &:hover{
                color: $primaryColor;
            }
        }
    }
}

.theme_card{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.theme_accordion{
    .accordion-item{
        .accordion-header{
            .accordion-button{               
                font-family: 'robotomedium';
                font-size: 0.55em;
                // text-transform: uppercase;
                &:not(.collapsed){
                    background-color: $primaryColor;
                    color: $white;
                    border-color:$primaryColor;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }    
}

.media_card{
    border: 4px solid $primaryColor;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius:4px;
    position: relative;
    img{
        width: 100%;
        height: auto;
        position: relative;        
    }
    &:hover{
        .img_overlay{
            opacity: 1;
        }
    }
    .img_overlay{
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0;
        filter: alpha(opacity = 0);
        overflow: hidden;
        transition: opacity 500ms ease-out;
        -moz-transition: opacity 500ms ease-out;
        -o-transition: opacity 500ms ease-out;
        -webkit-transition: opacity 500ms ease-out;
        display: flex;
        width: 100%;
        h4{
            color: $white;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            a{
                color: $white;
            }
        }
    }
    .media_text{
        background-color: $primaryColor;
        padding: 15px;
        h2{
            font-size: 1.5em;
            margin-bottom: 0;
            color:$white;
            font-size: 1.8em;
        }
    }
}

.vertical_tabs{
    .nav-pills{
       background-color: #fff;
       box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
       padding: 15px;
       border-radius: 10px;
        .nav-link{
            border-radius: 0;
            padding: 5px 0px;
            margin-bottom: 5px;
            color:$black;
            font-family: 'robotomedium';
            // width: max-content;
            text-transform: capitalize;
            img{
                height: 45px;
                width: auto;
            }
            &.active{
                background-color: transparent;
                color:$primaryColor;
                border-bottom:2px solid $primaryColor;                
            }
       }
    }
    &.buttons{
        .nav{
            .nav-item{
                // margin-right: 15px;
                // margin-bottom: 15px;
                width: 20%;
                padding: 5px 5px;
                text-align: center;
                @media screen and (max-width:769px){
                    width:33.33%;
                }
                @media screen and (max-width:576px){
                    width:50%;
                }
                @media screen and (max-width:376px){
                    width:100%;
                }
                a{
                    background-color: $secondaryColor;
                    border-radius: 50px;
                    padding: 10px 15px;
                    color: $black;
                    border-bottom: 0;
                    &.active{
                        background-color: $primaryColor;
                        color: $white;
                    }
                }
            }
        }
    }
}

.contact_info_list{
    padding-left: 0;
    li{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px 50px 20px 30px;
        background-color: #f8f8f8;
        border-radius: 25px;   
        @media screen and (max-width:576px) {
            padding:10px;
        }             
    }
}

.icon_div{
    background-color: $primaryColor;
    border-radius: 50%;
    margin-right: 15px;
    padding: 15px;
    display: inline-block;
    @media screen and (max-width:576px) {
        padding:10px;
    }  
    img{
        width:35px;
        height: auto;
        margin: 0 auto;
        @media screen and (max-width:576px) {
            width:25px;
        }  
    }    
}

.course_card{
    min-height: 275px;
    @media screen and (max-width:576px) {
        min-height: auto;
    }  
    a{
        color:$black;
    }
    &:hover{
        background-color: $primaryColor;
        color:$white;
    }
}

.courses{
    border-radius: 20px !important;
    border-radius: 20px 0px 0px 20px !important;  
    min-height: 213px;  
    .card_text{
        position: relative;
        z-index: 1;
        a{
            color: $black;
            text-decoration: none;
            font-size: 1.1em;
            font-family: 'robotomedium'; 
        }
        .course_tags{
            width: 75%;
            span{
                background-color: #909eb5;
                color:$white;
                padding: 5px 10px;
                border-radius: 20px;
                margin-right: 10px;
                display: inline-block;
                margin-bottom: 10px;
                min-width: 70px;
                text-align: center;
            }            
        }
    }
    .card_bg{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 25%;
        height: 100%;
        border-radius: 9999px 0px 0px 9999px;
        background-color: #D6E0F0;
        aspect-ratio: 1 / 1;
        background-color: $secondaryColor;
        @media screen  and (max-width:769px){
            width: 30%;
        }
        img{
            position: relative;
            right: -23%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12rem;
            line-height: 0;
            color: white;
            z-index: 0;
            width: 68px;
        }
    }
}
.center_card{
    position: relative !important;
    border:1px solid $primaryColor !important;
    margin:0px 5px;
    a{
        text-decoration: none;
    }
    &:hover{
        .corner_shape{
            -webkit-transform: rotate(180deg) scale(1);
            transform: rotate(180deg) scale(1);
        }
        background-color: $primaryColor;
        h5{
            color:$white;
        }
    }
    .corner_shape{
        position: absolute;
        top: -20px;
        left: -20px;
        bottom: auto;
        right: auto;
        background: 0 0;
        -webkit-transform: rotate(180deg) scale(0);
        transform: rotate(180deg) scale(0);
        color: #0eb582;
        width: 60px;
        height: 60px;
        transition: all .4s ease;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -ms-transition: all .4s ease;
    }
    .center_img{
        margin-right: 30px;
        img{
            width: 70px;
            height: 70px;
            border-radius: 8px;
            border: 1px solid #c9d0d6;
        }
    }
    h5{        
        color: $black;
    }
}
.cta_sec{
    position: relative;
    .carousel{        
        .carousel-item{
            max-height: 400px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #3e454d38;
                z-index: 1;
            }
        }
    }    
    .caption_text{
        z-index: 9;
        position: absolute;
        bottom: 3rem;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        text-align: center;
        h1, p{
            color: $white;
        }
        button{
            background-color: $primaryColor !important;
            border-color: $primaryColor !important;
        }
    }
    .carousel-indicators{
        button{
            background-color: $secondaryColor !important;
            height: 8px !important;
        }
    }
}
.theme_modal{
    z-index: 99999 !important;
}

.theme_list, .check_list{
    padding-left: 0px;
    li{
        list-style-type: none;
        margin-bottom: 10px;
        position: relative;
        padding-left: 35px;
        &:before{
            content: '';
            display: inline-block;
            height: 18px;
            width: 18px;
            background-image: url('../images/icons/right-chevron.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 4px;
        }
    }
}

.check_list{
    li{
        &:before{
            background-image: url('../images/icons/checklist.png');
        }
    }
}

.btn-group{
    .btn{
        &:first-child{
            margin-right: 15px;
        }
    }
}

.career_card{
    text-transform: capitalize;
}
// .career_card{
//     background-color:rgb(255 165 0 / 14%) !important;
//     text-transform: capitalize;
//     transition: all 0.3s ease;
//     &:hover{
//         background-color: $orange !important;
//         color:$white !Important;
//         .theme_btn{
//             color:$white !important;
//             border-color: $black!important;
//         }
//     }
// }

.detailTopCard{
    text-transform: capitalize;
    .title, .subtitle{
        color:$black;
    }
    span{
        color:$primaryColor;
        font-family: 'robotomedium';
    }
    .location{
        color:#707070
    }
}


.horizontal_tabs{
    border-bottom: 0px !important;
    .nav-item{
        border-bottom: 0px;
        flex-basis: auto !important;
        width: 25%;
        flex-grow: unset !important;
        padding: 10px 5px;
        @media screen and (max-width:1025px) {
            width: 33.33%;
        }
        @media screen and (max-width:590px) {
            width: 50%;
        }
        .nav-link{
            background-color: $secondaryColor;
            color: $black;
            font-size: 1.2em;
            border-radius: 5px;
            border: none;
            padding: 0 30px;
            height: 60px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            position: relative;
            z-index: 1;
            font-family: 'robotomedium';
            line-height: 1.2em;
            text-transform: capitalize;
            &.active, &:hover{
                background-color: $primaryColor;
                color:$white;
                text-transform: capitalize;
                &:after{
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid $primaryColor;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    visibility: hidden;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    z-index: -1;
                    bottom: -9px;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

.masonary-container{
column-count: 4;
column-gap: 10px;
figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
    img{
        grid-row: 1 / -1;
        grid-column: 1;
    }
}
}

.image_grid{
    img{
        display: inline-block;
        width: 33.33%;
        padding: 5px;
        max-width: 100%;
        @media screen and (max-width:576px) {
            width:50%;
            height: 180px;
        }
    }
}

#lightboxContainer{
    button{
        svg{
            fill:$white;
        }
    }
}

.flow_row{
    display: flow-root !important;
    -webkit-display:flow-root !important;
}

.tabs_btn{
    display: block !important;
    margin: 0 auto;
    color:$primaryColor !important;
    font-size: 1.5em !important;
    font-family: 'robotomedium' !important;
}
.student_card{
    border: 2px solid transparent !important;
    border-radius: 4px !important;
    img{
        max-height: 200px;
        @media screen and (max-width:769px){
            max-height: 150px;
        }
    }
    .content_div{
        padding: 15px;
        @media screen and (max-width:769px){
            padding:5px;
        }
        h1{
            font-size: 1.6em;
        }
        h2{
            color:$black;
        }
    }
    &:hover{
        border:2px solid $primaryColor !important;
        transition: all 0.3s ease;
    }
}

.swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}
@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}

.blog_card{
    .inner{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        .thumbnail{
            margin-right:40px;
            position: relative;
            flex: 40%;
            a{
                display: block;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                &:hover{
                    img{
                        &::after{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                img{
                    width: 100%;
                    border-radius: 10px;
                    transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;                
                    &::after{
                        content: "";
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, .5);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                    }
                }
            }
        }
        .content{
            padding: 0;
            position: relative;
            flex:60%;            
        }
    }
    &.latest_post{
        .inner{
            .thumbnail{
                margin-right: 10px;
                img{
                    border-radius: 5px;
                    max-width: 280px;
                    object-fit: cover;
                }
            }
        }
        border-bottom: 1px solid $secondaryColor;
    }
}
.category_list{
    list-style-type: none;
    padding-left: 0px;
    li{
        margin-bottom: 5px;
        a{
            text-decoration: none;
            color: $black;
            font-size:1.1em;
            &:hover{
                color:$primaryColor;
            }
        }
    }
}

.ellipsis_text{
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px;
}

.filter_container{
    display: flex;
    justify-content: end;
    h5{
        margin-right: 15px;
    }
    .custom_select.form-select{
        // border-color: $primaryColor;
        // box-shadow: 0 0 0 .25rem #f3743559;
        &:focus{
            border-color: $primaryColor;
        box-shadow: 0 0 0 .25rem #f3743559;
        }
        option{
            &:hover, &:focus{
                background-color: $primaryColor !important;
                color:$white !important;
            }
        }
    }
}

.category_text{
    background-color: $primaryColor;
    padding: 5px 15px;
    color: $white;
    border-radius: 5px;
}
.thumbnail{
    img{
        border-radius: 10px;
    }
}
.student_img{
    background-color: $secondaryColor;
    border-radius: 10px;
    img{
        display: block;
        margin: 0 auto;
        padding: 10px;
    }
}
.gallery_grid{
    margin-top: 30px;
    &.newsPapers{
        .image_grid{
            img{
                width: 25%;
                @media screen and (max-width:1025px){
                    width: 33.33%;
                }
                @media screen and (max-width:769px){
                    width: 50%;
                }
                @media screen and (max-width:576px){
                    width: 100%;
                }
            }
        }    
    }
    .image_grid{
        img{
            width: 25%;
            margin: 0px 0px 20px 0px;
            padding: 10px;
            border: 2px solid $primaryColor;
            height: 300px;
            object-fit: inherit;
        }
    }
}
.bg_secondary{
    background-color: $secondaryColor;
    padding: 30px;
}
.digital_tabs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .card{
        width: 43%;
        text-align: center;
        margin: 15px;
        a{
            color: $black;
            text-decoration: none;
        }
    }
}
.star_tabs{
    .nav-item{
        width: 15.7%;
        flex-grow: unset !important;
        flex-basis: unset !important;
        margin-bottom: 15px;
        .nav-link{
            padding: 0 15px;
        }
    }
}
.service{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        &:last-child{
            justify-content: flex-start !important;
        }
    }
    .icon{
        background-color: #b1a3a3;
        border-radius: 50%;
        margin-right: 15px;
        padding: 15px;
        display: inline-block;
        &:hover{
            background-color: $primaryColor;
        }
        img{
            width: 45px;
            height: 45px;
        }
    }
    .content{
        display: inline-block;
        p{
            margin-bottom: 0px;
            line-height: 1.3;
        }
    }
}
.centerImg{
    border-radius: 20px;
    max-height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}
.cta_btn{
    background-color:#e6e6e6;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
    border-radius: 0 0 10px 10px;
    padding: 15px;
}
.video_gallery{
    .thumbnail{
        position: relative;
        &:before{
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, .3);
            border-radius: 50px 10px 0 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        img{
            border-radius: 50px 10px 0 0;
            width: 100%;
        }
        .video_play_btn{
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border: 0;
            width: 80px;
            height: 80px;
            line-height: 82px;
            -webkit-transition: all 1s cubic-bezier(0,0,.2,1);
            transition: all 1s cubic-bezier(0,0,.2,1);
            border-radius: 50%;
            background: $primaryColor;
            margin: 0 auto;
            display: inline-block;
            font-size: 1.5em;
            text-align: center;
            color: $white;
            cursor: pointer;
            img{
                width: 25px;
                height: 25px;
                margin: 0 auto;
                border-radius: 0px;
                margin-left: 5px;
            }
            &:before, &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, .3);
                -webkit-animation: ripple 2s linear infinite;
                animation: ripple 2s linear infinite;
            }
            &:after{
                -webkit-animation: ripple 2s linear infinite !important;
                animation: ripple 2s linear infinite !important;
            }
        }
    }
    &.multi{
        .thumbnail{
            &:before{
                width: 93%;
                left: 12px;
            }
        }
    }
    &.testimonial{
        .thumbnail{
            &:before{
                border-radius: 8px 8px 0px 0px;
            }
            img{
                border-radius: 8px 8px 0px 0px;
            }
        }
        .video_play_btn{
            img{
                border-radius: 0px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
            }
        }
    }
}

@keyframes ripple
{
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.video_sec, .custom_sec{
    margin-bottom: 60px;
    h2{
        color: $primaryColor;
        font-size: 1.8em;
        font-family: "robotobold";
        text-transform: capitalize;
    }
}
#subVideoSecCarousel{
    padding-bottom: 20px;
    .carousel-control-prev, .carousel-control-next{
        display: none;
    }
    .carousel-indicators{
        bottom: -50px;
        button{
            width: 20px;
            height: 20px;
            margin-right: 8px;
            margin-left: 8px;
            border-radius: 50%;
            background-color: $primaryColor;
        }
    }
}
.masonry-grid{
    columns: 2;
	column-gap: 1rem;
    .thumbnail{
        display: block;
        width: 100%;
        margin-bottom: 1rem;
    }
}
.radius_img{
    border-radius: 40px;
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    object-position: bottom;
}

.flip-container {
    perspective: 1000px;
    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        .front, .back {
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            img{
                max-height: 500px;
            }
        }
        .front {
            z-index: 2;
            transform: rotateY(0deg);
        }          
        .back {
        transform: rotateY(180deg);
        }        
    }
    &.flipped{
        .flipper{
            transform: rotateY(180deg);
        }
    }
}
.stf__parent{margin: 0 auto;}
.stf__innerShadow, .stf__outerShadow, .stf__hardShadow, .stf__hardInnerShadow{
    bottom: 0;
    right: 0;
    height: 500px;
}
.pdf_header{
    display: flex;
    width:100%;
    h1{
        margin-right: 30px;
    }
    button{
        margin: 0px 10px;
        // padding: 4px 19px !important;
        // font-size: 1.1em !important;
        // max-height: 40px;
        // border: 1px solid #000 !important;
        &.btn-close{
            padding: 0px 28px !important;
        }
    }
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog{
    transition: all 0.3s ease !important;
}
@media (min-width: 576px) {
.modal .modal-dialog{    
    box-shadow: 0 0.5rem 1rem #00000026 !important;
}
}
.top_padding{
    padding-top: 120px;
    .minus_margin{
        margin-top: -65px;
        @media screen and (max-width: 576px) {
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 576px) {
        padding-top: 0px;
        img{
            margin-top: 30px;
        }
    }
}
.test_slider{
    .slick-slide{
        padding: 15px;
    }
}
.slick-dots{
    li{
        button{
            &:before{
                font-size: 15px !important;
                color:$primaryColor !important;
            }
        }
    }
}
.testimonial_grid{    
    .thumbnail{
        display: inline-block;
        position: relative;
        margin-bottom: 15px;
        .thumb_img{
            border-radius: 50%;
            height: 60px;
            width: 60px;
            object-fit: contain;
            border: 1px solid $primaryColor;
        }
        .quote_icon{
            height: 34px;
            width: 34px;
            line-height: 30px;
            font-size: 13px;
            background-color: $primaryColor;
            border-radius: 50%;
            border: 4px solid #fff;
            display: block;
            text-align: center;
            position: absolute;
            bottom: -8px;
            right: -12px;
            img{
                margin: 0 auto;
                padding: 4px;
            }
        }
    }
}
.btn_group{
    .btn{
        margin:0px 10px;
    }
}
// .pdf_img{
//     &.zoom-in{
//         transition: transform 0.25s ease;
//         cursor: zoom-in;
//     }
    
//     &:active{
//         -webkit-transform: scale(2);
//         transform: scale(2);
//         cursor: zoom-out;
//     }
// }
.pdf_buttons{
    display: flex;
}
.slick-arrow{
    display: none !important;
}
.academics_tabs{
    .nav-item{
        a{
            &:hover{
                background-color: $primaryColor !important;
                color:$white !important
            }
        }
    }
}
.dropdown-item{
    font-size: 1em;
}
.photographs{
    img{
        height: 300px;
        object-fit: cover;
        object-position: top;
    }
}
.captcha_div{
    width:100%;
    position: relative;
    .captchaRefreshBtn{
        color: #000;
        border: none;
        position: absolute;
        right: 2px;
        background-color: #fff;
        font-size: 0;
        top: 4px;
        padding: 0;
        left: auto;
        z-index: 11;
        &:before{
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: url('../images/icons/sprite.png') no-repeat;
            background-size: 14px;
            right: 6px;
            top: 6px;
        }
    }
}

.scroll_btn{
    position: fixed;
    bottom: 60px;
    right: 20px;
    padding: 5px;
    background-color: $primaryColor;
    text-align: center;
    border-radius: 50px;
    border: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    &.float_btn{
        bottom: 60px;
        right: 80px;
        &:hover{
            background-color: $primaryColor !important;
            color:#fff !important;
            border-color: $primaryColor !important;
        }
    }
    img{
        width: 40px;
    }
}
.cpa_title{
    font-size: 3em;
    line-height: inherit;
    font-style: italic;
    @media screen and (max-width:561px) {
        font-size: 2em;
    }
}
.cpa_subtitle{
    color: rgb(148 163 184);
    opacity: 1;
    font-size:1.15em;
    font-family: "robotoregular";
}
.cpa_main_img{
    max-height: 450px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.benifits_row{
    display: flex;
    align-items: center;
}
.center_text{
    max-width: 70%;
    margin: 0 auto;
}
.cpa_top{
    .top_left{
        img{
            width: 120px;
            @media screen and (max-width:561px) {
                width: 90px;
            }
        }
    }
    .top_center{
        padding-left: 15px;
    }
    .social_icon{
        ul li{
            background-color: #000;
            padding: 5px 8px;
            margin:0px 5px;
            border-radius: 4px;
            &:hover{
                background-color: $primaryColor;
            }
        }
    }
    &.sticky{
        position: fixed;
        background-color: $white;
        color: $black;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #cecece;
        -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);        
        animation: sticky 2s;
        -webkit-animation: sticky 2s;
        -moz-animation: sticky 2s;
        -ms-animation: sticky 2s;
        -o-animation: sticky 2s;
    }
}
.feature_sec{
    width: 100%;
    .feature_div{
        transition: all .4s ease;
        background-color: #F2F2F2;
        border-radius: 20px;
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        margin-bottom: 15px;
        @media screen and (max-width: 769px){
            justify-content: flex-start;
            text-align: left;
        }
        &:hover{
            background-color: $primaryColor;
            color:$white;
            .icon{
                border:2px solid $white;
            }
        }
        .icon{
            background-color: $primaryColor;
            border-radius: 50px;
            padding: 15px;
            display: inline-block;
            border:2px solid transparent;
           
            img{
                width: 50px;
                height: auto;
            }
        }
        h6{
            margin: 0px;
            font-size: 1.2em;
            align-self: center;
            margin-left: 10px;
        }
    }
}
.overlap_sec{
   margin-top: -130px; 
}
.large_bottom{
    padding-bottom: 130px;
}
.cpa_strip{
    background-color: $primaryColor;
    color:$white;        
    // padding: 30px;
    .cpa_strip_content{
        padding: 15px;
        display: flex;
        width: 100%;   
        vertical-align: middle;
        align-self: center; 
        &.mob{
             @media screen and (min-width:769px) {
                display: none;
            }
            @media screen and (max-width:769px) {
                display: block;
            }
        }
        @media screen and (max-width:769px) {
            display: none;
            .content{
                display: block !important;
                .first{
                    // margin-bottom: 30px;
                    padding: 5px;
                }
                .second, .first{
                    width: 100%;
                    justify-content: center;
                }
            }
        }
        .first{
            border:2px solid #fff; 
            // margin-right:30px;
            padding: 15px;
            width: 35%;
            align-self: center;
        }   
        .second{
            // margin-right: 30%;
            width: 53%;
            .btn{
                min-width: 50%;
            }
        }
        .third{
            width: 12%;
            align-self: center;
        }
    }
    .title{
        color:$white;
    }    
}

.ribbon {
    --r: .8em; /* control the ribbon shape */    
    padding-inline: calc(var(--r) + .3em);
    line-height: 1.8;
    clip-path: polygon(0 0,100% 0,calc(100% - var(--r)) 50%,100% 100%,0 100%, var(--r) 50%);
    background: #fff; /* the main color */
    width: fit-content;
    display: block;
    margin: 0 auto;
    font-family: 'robotobold';
    .date_strip{
        margin-bottom: 0;
        padding: 5px;
        color:#000;
    }
}

.cpa_form_sec{
    border-bottom: 2px solid #000;
    background-color: #D6E0F0;
    .content_right{
        background-color: $secondaryColor;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:576px) {
            padding: 15px;
        }
        .cpa_form{
            background-color: $primaryColor;
            align-self: center;
            width: 100%;
            border: 8px solid #fff;
            padding: 30px;
            label{
                font-size: 1.4em;
                color: #fff;
            }
            .form-control{
                height: 50px;
                margin-bottom: 15px;
            }
        }
    }   
    .content_left{       
        // background-color: #212529e3;
        // background-color: #fff;
        padding: 30px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:576px) {
            padding: 15px;
        }
        // color: #fff;        
        .blackboard{
            background-color: #d5d9dc;
            border-radius: 40px;
            border: 8px solid #000;
            padding: 30px 60px;
            text-align: center;
            @media screen and (max-width:1025px){
                padding: 15px;
            }
            @media screen and (max-width:400px){
                h1{
                    font-size: calc(1.375rem + 0.5vw);
                }
            }
            // img{
            //     max-width: 300px !important;
            //     @media screen and (max-width:'768px'){
            //         max-width: 100% !important;
            //     }
            // }
            .cpa_text{
                background-color: #fff;
                color: #000;
                border-radius: 8px;
                padding: 5px;
                display: table;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
}
.abs_images{
    .abs_img{
        width: 180px;
        height: 180px;
        border-radius: 100%;
        background-color: $secondaryColor;
        position: absolute;
        border:2px dashed $primaryColor;
        padding:10px;
        @media screen and (max-width:1025px) {
            width: 150px;
            height: 150px;
        }         
        &.one{           
            bottom: 10%; 
            @media screen and (max-width:376px) {
                left: 10px;
            }           
            .tooltip_text{
                background-color: #212529;
                padding: 5px;
                border-radius: 8px;
                position: absolute;
                left: 80%;
                bottom: 0;
                font-weight: 500;
                width: 200px;
                color: #fff;
                &::before{
                    content: '';
                    position: absolute;
                    display: block;    
                    width: 0px;        
                    left: 0;
                    top: 11%;
                    border: 8px solid transparent;
                    border-left: 0;
                    border-right: 15px solid #000;
                    transform: translate(calc(-100% - -3px), -50%);
                }
            }
        }
        &.two{
            right: 8%;
            top: 8%;   
            @media screen and (max-width:769px) {
                right: 8%;
                top: 38%;
            }  
            @media screen and (max-width:376px) {
                right: 8%;
                top: 42%;
            }  
            .tooltip_text{
                background-color: #fff;
                padding: 5px;
                border-radius: 8px;
                position: absolute;
                left: -82%;
                bottom: 33%;
                font-weight: 500;
                &::before{
                    content: '';
                    position: absolute;
                    display: block;    
                    width: 0px;        
                    right: 0;
                    top: 50%;
                    border: 8px solid transparent;
                    border-right: 0;
                    border-left: 15px solid #fff;
                    transform: translate(calc(100%), -50%);
                }
            }   
        }
        img{
            width: 100%;
            border-radius: 100%;
        }
    }    
}
.cpa_form{
    border-radius: 8px;
    &.img_div{
        background-color: #fff;
    border-radius: 20px;
    // padding: 30px;
    border: 8px solid #fff;
    // max-height: 616px;
    // display: table-cell;
    width: 100%;
    // display: flex;
    img{
        height: 100%;
        border-radius: 8px;
        max-height: 595px;
        width: 100%;
    }
    }
}

.banner_sec{
    .banner_slider{
        &.desk_view{
            display: block;
            @media screen and (max-width:769px){
                display: none;
            }
        }
        &.mob_view{
            display: none;
            @media screen and (max-width:769px){
                display: block;
            }                
        }
    }
}








@media screen and (max-width:1025px){
    .modal .title{
        font-size: 1.2em;
        justify-content: flex-end;
    }
    .pdf_header{
        display: block;
    }
    .pdf_buttons{
        display: block;
        margin-top: 10px;
        button{
            margin: 0px 5px;
            font-size: 0.9em !important;
        }
    }
}
@media screen and (max-width:769px){
    .mb-mid{
        margin-bottom: 15px !important;
    }
    .cpa_top{
        .top_right{
            display: none;
        }
    }
}
@media screen and (max-width:576px) {
    .almuni_slider .slick-dots{
        margin-bottom: 50px !important;
    }
    .mb-small{
        margin-bottom: 15px !important;
    }
    .dropdown-item{
        font-size: 0.80em;
    }
    .top_nav{
        .d-flex{
            display: block !important;           
            .top_left, .top_right{
                width: 100% !important;
                justify-content: flex-start !important;
            }    
            .top_left{
                margin-bottom: 10px !important;
            }        
        } 
        &.cpa_top{
            .d-flex{
                display: flex !important;
                .top_left{
                    width: 30% !important;
                    justify-content: flex-start !important;
                } 
                .top_center{
                    width: 70% !important;
                    justify-content: flex-start !important;
                }  
            }
        }       
    }    
    .slick-dots{
        margin-bottom: 10px;
    }
}
@media screen and (min-width:1024px) {
   .banner_slider{
        .carousel-item{
            img{
                height: 500px;
                object-fit: inherit;
            }
        }
   }
}