/*============= Color Variables  ================*/
@font-face {
  font-family: "robotoblack_italic";
  src: url("../fonts/Roboto/roboto-blackitalic-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold";
  src: url("../fonts/Roboto/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight";
  src: url("../fonts/Roboto/roboto-light-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight_italic";
  src: url("../fonts/Roboto/roboto-lightitalic-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium";
  src: url("../fonts/Roboto/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium_italic";
  src: url("../fonts/Roboto/roboto-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoregular";
  src: url("../fonts/Roboto/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin";
  src: url("../fonts/Roboto/roboto-thin-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin_italic";
  src: url("../fonts/Roboto/roboto-thinitalic-webfont.woff2") format("woff2"), url("../fonts/Roboto/roboto-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-size: 16px;
  font-family: "robotoregular";
  background-color: #fff;
}
@media only screen and (min-width: 1920px) {
  body {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1024px) {
  body {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 480px) {
  body {
    font-size: 13px !important;
  }
}

p {
  font-size: 1.1em;
}

.mt-50 {
  margin-top: 50px;
}
@media screen and (max-width: 576px) {
  .mt-50 {
    margin-top: 25px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pink {
  color: #ff0080;
}

.orange {
  color: #ffa500;
}

.blue {
  color: #0080ff;
}

.brown {
  color: #cc3333;
}

.bg_light_gray {
  background-color: rgba(238, 227, 227, 0.54);
  padding: 50px 0px;
}
@media screen and (max-width: 576px) {
  .bg_light_gray {
    padding: 25px 0px;
  }
}

.bg_purple {
  background-color: purple;
}

.relative {
  position: relative;
}

.bold {
  font-family: "robotobold";
}

.underline {
  border-bottom: 2px solid #F37435;
}

.primary_color {
  color: #F37435;
}

.header_wrapper {
  background-color: #fff;
}
.header_wrapper .navbar {
  background-color: transparent;
  box-shadow: none;
  border: 0;
  color: #fff;
  border-bottom: 1px solid #F37435;
}

.banner_sec .popout_images {
  display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  -webkit-display: flex;
  align-self: center;
}
@media screen and (max-width: 769px) {
  .banner_sec .popout_images {
    display: none;
    -moz-display: none;
    -ms-display: none;
    -o-display: none;
    -webkit-display: none;
  }
}
.banner_sec .popout_images img, .banner_sec .popout_images video {
  position: absolute;
  bottom: 0;
  width: 20%;
  height: auto;
  border: 4px solid #fff;
  border-radius: 10px;
}
.banner_sec .popout_images img.left, .banner_sec .popout_images video.left {
  left: 0;
}
.banner_sec .popout_images img.right, .banner_sec .popout_images video.right {
  right: 0;
}

.banner_slider .carousel-indicators {
  display: none;
}
.banner_slider .carousel-indicators button {
  background-color: #F37435 !important;
  width: 40px;
  height: 10px;
}
.banner_slider.cpa_banner {
  border-bottom: 2px solid #F37435;
  border-top: 2px solid #F37435;
}
.banner_slider.cpa_banner .carousel-indicators {
  bottom: 0px !important;
}
.banner_slider.cpa_banner .carousel-control-prev, .banner_slider.cpa_banner .carousel-control-next {
  display: none;
}
@media screen and (max-width: 769px) {
  .banner_slider .carousel-item img {
    min-height: 250px;
    width: 100% !important;
  }
}

.pdf_div {
  background-color: gray;
  padding: 20px;
  text-align: center;
  font-size: 1.5em;
  color: #fff;
  border-radius: 20px;
}
.pdf_div a {
  color: #fff;
  text-decoration: none;
  font-family: "robotobold";
  font-size: 1.1em;
}
.pdf_div a img {
  width: 100px;
  height: auto;
  margin-top: 20px;
}

.topBottom {
  animation: topbottom 5s ease infinite alternate;
  -webkit-animation: topbottom 5s ease infinite alternate;
  -moz-animation: topbottom 5s ease infinite alternate;
  -ms-animation: topbottom 5s ease infinite alternate;
  -o-animation: topbottom 5s ease infinite alternate;
}

@keyframes topbottom {
  0% {
    bottom: 0%;
  }
  100% {
    bottom: 25%;
  }
}
.flex_component {
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  justify-content: center;
}

.title {
  font-size: 2.1875em;
  font-family: "robotobold";
  color: #F37435;
  text-transform: capitalize;
}

.subtitle {
  text-transform: capitalize;
  font-size: 1.7em;
  color: #F37435;
  font-family: "robotobold";
}
.subtitle.purple {
  color: #7952b3;
}

.img_radius {
  border-radius: 20px;
}

.underline {
  border-bottom: 3px solid #F37435;
  width: max-content;
}

.top_nav {
  background-color: #F37435;
  padding: 10px 0px;
  color: #fff;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  width: 100%;
}
.top_nav .top_left, .top_nav .top_right, .top_nav .top_center {
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.top_nav .top_left ul, .top_nav .top_right ul, .top_nav .top_center ul {
  margin: 0;
  padding-left: 0px;
}
.top_nav .top_left ul li, .top_nav .top_right ul li, .top_nav .top_center ul li {
  list-style-type: none;
  display: inline-block;
  padding-right: 15px;
}
.top_nav .top_left {
  justify-content: flex-start;
}
.top_nav .top_right {
  justify-content: flex-end;
}
@media screen and (max-width: 640px) {
  .top_nav .top_right {
    width: 70%;
  }
}
.top_nav.cpa_top {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  color: #000;
}

.svg_img {
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 640px) {
  .svg_img {
    width: 20px;
    height: 20px;
  }
}

.dropdown-item.active, .dropdown-item.focus, .dropdown-item.hover, .dropdown-item:active {
  background-color: transparent !important;
}

.navbar .nav-link, .navbar .nav-item {
  color: #000;
  font-size: 1em;
  font-family: "robotomedium";
}
.navbar .nav-link.active, .navbar .nav-item.active {
  color: #F37435 !important;
}
.navbar .nav-link.show .nav-link, .navbar .nav-item.show .nav-link {
  color: #F37435 !important;
}
.navbar.sticky {
  position: fixed;
  background-color: #fff;
  color: #000;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #cecece;
  -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  animation: sticky 2s;
  -webkit-animation: sticky 2s;
  -moz-animation: sticky 2s;
  -ms-animation: sticky 2s;
  -o-animation: sticky 2s;
}
.navbar.sticky .nav-link, .navbar.sticky .nav-item {
  color: #000;
}
.navbar.sticky .nav-link.show, .navbar.sticky .nav-item.show {
  color: #F37435 !important;
}
.navbar a:hover {
  color: #F37435;
}
.navbar a.active {
  background-color: transparent !important;
}
.navbar .navbar-brand img {
  width: 120px;
}

.breadcrumb_bg {
  background-image: url("../images/breadcrumb-bg.jpeg");
  background-repeat: repeat;
  text-align: center;
  padding: 100px 15px;
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 576px) {
  .breadcrumb_bg {
    max-height: 200px;
    min-height: 200px;
  }
}
@media only screen and (max-width: 769px) {
  .breadcrumb_bg {
    background-position: center;
  }
}
.breadcrumb_bg .breadcrumb_content {
  font-family: "robotobold";
  text-transform: capitalize;
}
.breadcrumb_bg .breadcrumb_content .breadcrumb {
  align-items: center;
  display: inline-flex;
  -webkit-display: inline-flex;
  -moz-display: inline-flex;
  -ms-display: inline-flex;
  -o-display: inline-flex;
  margin-bottom: 0px;
}
.breadcrumb_bg .breadcrumb_content .breadcrumb .breadcrumb-item a {
  color: #F37435;
}

.about_card {
  align-items: center;
}
.about_card .card {
  padding: 30px;
  text-align: center;
  width: 150px;
  height: 150px;
  margin: 15px 15px 0px 15px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.about_card .card:hover {
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.about_card .card a {
  text-decoration: none;
  color: #fff;
  margin: 0px;
}
.about_card .card a img {
  width: 50px;
}
.about_card .card a p {
  padding-top: 10px;
}
.about_card .card1 {
  background-color: #ff0080;
}
.about_card .card2 {
  background-color: #0080ff;
}
.about_card .card3 {
  background-color: #F37435;
}
.about_card .card4 {
  background-color: #cc3333;
}

.counter_sec .container {
  text-align: center;
}
.counter_sec .container .main_div {
  background-color: #F37435;
  padding: 30px 15px 30px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
  -moz-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
  -ms-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
  -o-box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
  box-shadow: 0px 8px 15px 0px rgba(40, 37, 104, 0.4);
  color: #fff;
}
.counter_sec .container .main_div .counter_item {
  text-align: center;
  position: relative;
}
@media screen and (max-width: 591px) {
  .counter_sec .container .main_div .counter_item {
    border-bottom: 1px solid #fff;
  }
}
.counter_sec .container .main_div .counter_item::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 2px;
  height: 108px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (max-width: 591px) {
  .counter_sec .container .main_div .counter_item::before {
    display: none;
  }
}
.counter_sec .container .counter {
  font-size: 3em;
}
.counter_sec .container .counter_text {
  font-size: 1.125em;
  font-family: "robotomedium";
}

.counter_sec .main_div .row [class*=col-]:last-child .counter_item::before {
  display: none;
}

.counter_sec .main_div .row [class*=col-]:last-child .counter_item {
  border-bottom: none;
}

.border_right {
  border-right: 2px solid #000;
  padding: 0px 5px;
}

.almuni_slider .slick-dots {
  position: relative !important;
}
.almuni_slider .slick-slide {
  position: relative;
  padding: 15px;
}
.almuni_slider .slick-slide .card {
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-top: 100px;
}
.almuni_slider .slick-slide .card .card-title {
  font-size: 1.125em;
}
.almuni_slider .slick-slide .card .card-subtitle {
  color: #7952b3;
  margin-bottom: 15px;
}
.almuni_slider .slick-slide .card .card-img {
  border: 4px solid #fff;
  height: 130px;
  width: 130px;
  display: block;
  margin: -58px auto 0 auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.almuni_slider .slick-slide .card .card-img img {
  width: 120px;
  height: 120px;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.top_footer [class*=col] {
  padding: 12px;
  position: relative;
  flex: 14.28%;
}
@media screen and (max-width: 768px) {
  .top_footer [class*=col] {
    flex: 33.33%;
  }
}
.top_footer a {
  text-decoration: none;
}
.top_footer a:hover {
  background-color: rgba(222, 226, 230, 0.62);
  text-decoration: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 12px;
}
.top_footer img {
  width: 50px;
  height: auto;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 768px) {
  .top_footer img {
    width: 35px;
  }
}
.top_footer h3 {
  font-size: 1.0625em;
  margin-top: 15px;
  text-align: center;
  color: #fff;
}

.footer {
  background: url("../images/footer-banner.jpeg");
  background-repeat: repeat;
  background-position: right;
  padding: 30px 0px;
  color: #000;
}
.footer .qrcode {
  width: 100%;
  height: auto;
}
.footer .logo {
  width: 200px;
}
.footer h4.head {
  margin-top: 10px;
  color: #F37435;
  font-family: "robotobold";
}
.footer .social_icon {
  margin-top: 20px;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.footer .social_icon ul {
  padding-left: 0px;
  margin-bottom: 5px;
}
.footer .social_icon ul li {
  list-style-type: none;
  display: inline-block;
  padding-right: 15px;
  background-color: #000;
  padding: 2px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #000;
}
.footer .social_icon ul li:hover {
  background-color: #F37435;
  border: 1px solid #F37435;
}
.footer p {
  font-family: "robotomedium";
}
.footer .footer_heading {
  margin-bottom: 5px;
  color: #F37435;
  font-size: 1.125em;
  text-transform: uppercase;
  font-family: "robotobold";
}
.footer .footer_link {
  display: block;
  color: #000;
  text-decoration: none;
  font-family: "robotomedium";
  text-transform: capitalize;
}
.footer .footer_link:hover {
  transition: padding 0.4s;
  padding-left: 10px;
  color: #F37435;
}
.footer .other_links {
  display: flex;
  justify-content: center;
  width: 100%;
}
.footer .other_links .footer_link::after {
  content: "";
  border-right: 1px solid #000;
  border-width: 1px;
  margin: 0 15px;
}
.footer .other_links .footer_link:last-child::after {
  content: none;
}

.bottom_footer {
  background-color: #F37435;
  color: #fff;
  padding: 15px 0px;
  font-family: "robotomedium";
}

.core_cards {
  display: flex;
}
@media screen and (max-width: 769px) {
  .core_cards {
    flex-wrap: wrap;
    flex-direction: row;
  }
}
.core_cards .card {
  width: 100%;
  margin: 0px 10px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
@media screen and (max-width: 769px) {
  .core_cards .card {
    flex: 33.33%;
    margin-bottom: 15px;
  }
}
.core_cards .card:hover {
  transform: translateY(-10px);
}
@media screen and (max-width: 576px) {
  .core_cards .card .card-body {
    padding: 10px;
  }
}
.core_cards .card .card-body img {
  display: block;
  margin: 0 auto;
  background-color: #F37435;
  border-radius: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .core_cards .card .card-body img {
    width: 45px;
  }
}
.core_cards .card .card-body .card-title {
  text-transform: capitalize;
  font-size: 1.125em;
  margin-bottom: 0px;
}

.info-card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.info-card .card-body {
  padding: 0;
}
.info-card .card-body .card-title {
  text-align: center;
  text-transform: uppercase;
  color: #F37435;
  padding: 15px;
  font-family: "robotobold";
  margin-bottom: 0px;
}
@media screen and (max-width: 1025px) {
  .info-card .card-body .card-title {
    padding: 10px 5px;
    font-size: 1em;
  }
}
.info-card .card-body img {
  border-radius: 4px;
  height: 300px;
  width: 100%;
  object-fit: fill;
}
@media screen and (max-width: 1025px) {
  .info-card .card-body img {
    height: 210px;
    object-fit: cover;
    object-position: top;
  }
}
@media screen and (max-width: 576px) {
  .info-card .card-body img {
    height: auto;
    object-fit: cover;
    object-position: top;
  }
}
.info-card .hover-text {
  display: none;
  transition: all 0.8s ease;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.92);
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  padding: 50px 10px;
  border-radius: 4px;
}
.info-card .hover-text p {
  margin-bottom: 5px;
  font-family: "robotomedium";
}
.info-card .hover-text .card-title {
  text-transform: uppercase;
  color: #F37435;
  font-family: "robotobold";
}
.info-card:hover .hover-text {
  display: block;
  transform: translateY(10);
}

.theme_btn {
  background-color: #000 !important;
  border: 1px solid #000 !important;
  font-size: 1.125em !important;
  border-radius: 80px !important;
  padding: 7px 20px !important;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .theme_btn {
    padding: 7px 12px !important;
  }
}
.theme_btn:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.custom_list {
  padding-left: 10px;
  margin-bottom: 0px;
}
.custom_list li {
  padding-left: 3px;
}
.custom_list li a {
  text-decoration: none;
  color: #000;
}
.custom_list li a:hover {
  color: #F37435;
}
.custom_list li::marker {
  content: ">";
  color: #F37435;
  padding-right: "2px";
  font-family: "robotobold";
}

.theme_tabs li .nav-link {
  border: 0;
  color: #000;
  font-family: "robotomedium";
  font-size: 1.1em;
}
.theme_tabs li .nav-link:hover {
  color: #F37435;
}
.theme_tabs li .nav-link.active {
  color: #F37435;
  border-bottom: 2px solid;
}
.theme_tabs .sub_tabs {
  background-color: #D6E0F0;
  padding: 15px;
}

.download_list {
  padding-left: 10px;
  margin-bottom: 0;
}
.download_list li {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 5px;
}
.download_list li a:hover {
  color: #F37435;
}

.theme_card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.theme_accordion .accordion-item .accordion-header .accordion-button {
  font-family: "robotomedium";
  font-size: 0.55em;
}
.theme_accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #F37435;
  color: #fff;
  border-color: #F37435;
}
.theme_accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.media_card {
  border: 4px solid #F37435;
  text-align: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
}
.media_card img {
  width: 100%;
  height: auto;
  position: relative;
}
.media_card:hover .img_overlay {
  opacity: 1;
}
.media_card .img_overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
  transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  -webkit-transition: opacity 500ms ease-out;
  display: flex;
  width: 100%;
}
.media_card .img_overlay h4 {
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.media_card .img_overlay h4 a {
  color: #fff;
}
.media_card .media_text {
  background-color: #F37435;
  padding: 15px;
}
.media_card .media_text h2 {
  font-size: 1.5em;
  margin-bottom: 0;
  color: #fff;
  font-size: 1.8em;
}

.vertical_tabs .nav-pills {
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
}
.vertical_tabs .nav-pills .nav-link {
  border-radius: 0;
  padding: 5px 0px;
  margin-bottom: 5px;
  color: #000;
  font-family: "robotomedium";
  text-transform: capitalize;
}
.vertical_tabs .nav-pills .nav-link img {
  height: 45px;
  width: auto;
}
.vertical_tabs .nav-pills .nav-link.active {
  background-color: transparent;
  color: #F37435;
  border-bottom: 2px solid #F37435;
}
.vertical_tabs.buttons .nav .nav-item {
  width: 20%;
  padding: 5px 5px;
  text-align: center;
}
@media screen and (max-width: 769px) {
  .vertical_tabs.buttons .nav .nav-item {
    width: 33.33%;
  }
}
@media screen and (max-width: 576px) {
  .vertical_tabs.buttons .nav .nav-item {
    width: 50%;
  }
}
@media screen and (max-width: 376px) {
  .vertical_tabs.buttons .nav .nav-item {
    width: 100%;
  }
}
.vertical_tabs.buttons .nav .nav-item a {
  background-color: #D6E0F0;
  border-radius: 50px;
  padding: 10px 15px;
  color: #000;
  border-bottom: 0;
}
.vertical_tabs.buttons .nav .nav-item a.active {
  background-color: #F37435;
  color: #fff;
}

.contact_info_list {
  padding-left: 0;
}
.contact_info_list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 50px 20px 30px;
  background-color: #f8f8f8;
  border-radius: 25px;
}
@media screen and (max-width: 576px) {
  .contact_info_list li {
    padding: 10px;
  }
}

.icon_div {
  background-color: #F37435;
  border-radius: 50%;
  margin-right: 15px;
  padding: 15px;
  display: inline-block;
}
@media screen and (max-width: 576px) {
  .icon_div {
    padding: 10px;
  }
}
.icon_div img {
  width: 35px;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .icon_div img {
    width: 25px;
  }
}

.course_card {
  min-height: 275px;
}
@media screen and (max-width: 576px) {
  .course_card {
    min-height: auto;
  }
}
.course_card a {
  color: #000;
}
.course_card:hover {
  background-color: #F37435;
  color: #fff;
}

.courses {
  border-radius: 20px !important;
  border-radius: 20px 0px 0px 20px !important;
  min-height: 213px;
}
.courses .card_text {
  position: relative;
  z-index: 1;
}
.courses .card_text a {
  color: #000;
  text-decoration: none;
  font-size: 1.1em;
  font-family: "robotomedium";
}
.courses .card_text .course_tags {
  width: 75%;
}
.courses .card_text .course_tags span {
  background-color: #909eb5;
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  min-width: 70px;
  text-align: center;
}
.courses .card_bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  height: 100%;
  border-radius: 9999px 0px 0px 9999px;
  background-color: #D6E0F0;
  aspect-ratio: 1/1;
  background-color: #D6E0F0;
}
@media screen and (max-width: 769px) {
  .courses .card_bg {
    width: 30%;
  }
}
.courses .card_bg img {
  position: relative;
  right: -23%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12rem;
  line-height: 0;
  color: white;
  z-index: 0;
  width: 68px;
}

.center_card {
  position: relative !important;
  border: 1px solid #F37435 !important;
  margin: 0px 5px;
}
.center_card a {
  text-decoration: none;
}
.center_card:hover {
  background-color: #F37435;
}
.center_card:hover .corner_shape {
  -webkit-transform: rotate(180deg) scale(1);
  transform: rotate(180deg) scale(1);
}
.center_card:hover h5 {
  color: #fff;
}
.center_card .corner_shape {
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: auto;
  right: auto;
  background: 0 0;
  -webkit-transform: rotate(180deg) scale(0);
  transform: rotate(180deg) scale(0);
  color: #0eb582;
  width: 60px;
  height: 60px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}
.center_card .center_img {
  margin-right: 30px;
}
.center_card .center_img img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  border: 1px solid #c9d0d6;
}
.center_card h5 {
  color: #000;
}

.cta_sec {
  position: relative;
}
.cta_sec .carousel .carousel-item {
  max-height: 400px;
  position: relative;
}
.cta_sec .carousel .carousel-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(62, 69, 77, 0.2196078431);
  z-index: 1;
}
.cta_sec .caption_text {
  z-index: 9;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  text-align: center;
}
.cta_sec .caption_text h1, .cta_sec .caption_text p {
  color: #fff;
}
.cta_sec .caption_text button {
  background-color: #F37435 !important;
  border-color: #F37435 !important;
}
.cta_sec .carousel-indicators button {
  background-color: #D6E0F0 !important;
  height: 8px !important;
}

.theme_modal {
  z-index: 99999 !important;
}

.theme_list, .check_list {
  padding-left: 0px;
}
.theme_list li, .check_list li {
  list-style-type: none;
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
}
.theme_list li:before, .check_list li:before {
  content: "";
  display: inline-block;
  height: 18px;
  width: 18px;
  background-image: url("../images/icons/right-chevron.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 4px;
}

.check_list li:before {
  background-image: url("../images/icons/checklist.png");
}

.btn-group .btn:first-child {
  margin-right: 15px;
}

.career_card {
  text-transform: capitalize;
}

.detailTopCard {
  text-transform: capitalize;
}
.detailTopCard .title, .detailTopCard .subtitle {
  color: #000;
}
.detailTopCard span {
  color: #F37435;
  font-family: "robotomedium";
}
.detailTopCard .location {
  color: #707070;
}

.horizontal_tabs {
  border-bottom: 0px !important;
}
.horizontal_tabs .nav-item {
  border-bottom: 0px;
  flex-basis: auto !important;
  width: 25%;
  flex-grow: unset !important;
  padding: 10px 5px;
}
@media screen and (max-width: 1025px) {
  .horizontal_tabs .nav-item {
    width: 33.33%;
  }
}
@media screen and (max-width: 590px) {
  .horizontal_tabs .nav-item {
    width: 50%;
  }
}
.horizontal_tabs .nav-item .nav-link {
  background-color: #D6E0F0;
  color: #000;
  font-size: 1.2em;
  border-radius: 5px;
  border: none;
  padding: 0 30px;
  height: 60px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  z-index: 1;
  font-family: "robotomedium";
  line-height: 1.2em;
  text-transform: capitalize;
}
.horizontal_tabs .nav-item .nav-link.active, .horizontal_tabs .nav-item .nav-link:hover {
  background-color: #F37435;
  color: #fff;
  text-transform: capitalize;
}
.horizontal_tabs .nav-item .nav-link.active:after, .horizontal_tabs .nav-item .nav-link:hover:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #F37435;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
  bottom: -9px;
  visibility: visible;
  opacity: 1;
}

.masonary-container {
  column-count: 4;
  column-gap: 10px;
}
.masonary-container figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}
.masonary-container figure img {
  grid-row: 1/-1;
  grid-column: 1;
}

.image_grid img {
  display: inline-block;
  width: 33.33%;
  padding: 5px;
  max-width: 100%;
}
@media screen and (max-width: 576px) {
  .image_grid img {
    width: 50%;
    height: 180px;
  }
}

#lightboxContainer button svg {
  fill: #fff;
}

.flow_row {
  display: flow-root !important;
  -webkit-display: flow-root !important;
}

.tabs_btn {
  display: block !important;
  margin: 0 auto;
  color: #F37435 !important;
  font-size: 1.5em !important;
  font-family: "robotomedium" !important;
}

.student_card {
  border: 2px solid transparent !important;
  border-radius: 4px !important;
}
.student_card img {
  max-height: 200px;
}
@media screen and (max-width: 769px) {
  .student_card img {
    max-height: 150px;
  }
}
.student_card .content_div {
  padding: 15px;
}
@media screen and (max-width: 769px) {
  .student_card .content_div {
    padding: 5px;
  }
}
.student_card .content_div h1 {
  font-size: 1.6em;
}
.student_card .content_div h2 {
  color: #000;
}
.student_card:hover {
  border: 2px solid #F37435 !important;
  transition: all 0.3s ease;
}

.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.blog_card .inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.blog_card .inner .thumbnail {
  margin-right: 40px;
  position: relative;
  flex: 40%;
}
.blog_card .inner .thumbnail a {
  display: block;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.blog_card .inner .thumbnail a:hover img::after {
  visibility: visible;
  opacity: 1;
}
.blog_card .inner .thumbnail a img {
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.blog_card .inner .thumbnail a img::after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.blog_card .inner .content {
  padding: 0;
  position: relative;
  flex: 60%;
}
.blog_card.latest_post {
  border-bottom: 1px solid #D6E0F0;
}
.blog_card.latest_post .inner .thumbnail {
  margin-right: 10px;
}
.blog_card.latest_post .inner .thumbnail img {
  border-radius: 5px;
  max-width: 280px;
  object-fit: cover;
}

.category_list {
  list-style-type: none;
  padding-left: 0px;
}
.category_list li {
  margin-bottom: 5px;
}
.category_list li a {
  text-decoration: none;
  color: #000;
  font-size: 1.1em;
}
.category_list li a:hover {
  color: #F37435;
}

.ellipsis_text {
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}

.filter_container {
  display: flex;
  justify-content: end;
}
.filter_container h5 {
  margin-right: 15px;
}
.filter_container .custom_select.form-select:focus {
  border-color: #F37435;
  box-shadow: 0 0 0 0.25rem rgba(243, 116, 53, 0.3490196078);
}
.filter_container .custom_select.form-select option:hover, .filter_container .custom_select.form-select option:focus {
  background-color: #F37435 !important;
  color: #fff !important;
}

.category_text {
  background-color: #F37435;
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
}

.thumbnail img {
  border-radius: 10px;
}

.student_img {
  background-color: #D6E0F0;
  border-radius: 10px;
}
.student_img img {
  display: block;
  margin: 0 auto;
  padding: 10px;
}

.gallery_grid {
  margin-top: 30px;
}
.gallery_grid.newsPapers .image_grid img {
  width: 25%;
}
@media screen and (max-width: 1025px) {
  .gallery_grid.newsPapers .image_grid img {
    width: 33.33%;
  }
}
@media screen and (max-width: 769px) {
  .gallery_grid.newsPapers .image_grid img {
    width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .gallery_grid.newsPapers .image_grid img {
    width: 100%;
  }
}
.gallery_grid .image_grid img {
  width: 25%;
  margin: 0px 0px 20px 0px;
  padding: 10px;
  border: 2px solid #F37435;
  height: 300px;
  object-fit: inherit;
}

.bg_secondary {
  background-color: #D6E0F0;
  padding: 30px;
}

.digital_tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.digital_tabs .card {
  width: 43%;
  text-align: center;
  margin: 15px;
}
.digital_tabs .card a {
  color: #000;
  text-decoration: none;
}

.star_tabs .nav-item {
  width: 15.7%;
  flex-grow: unset !important;
  flex-basis: unset !important;
  margin-bottom: 15px;
}
.star_tabs .nav-item .nav-link {
  padding: 0 15px;
}

.service {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .service:last-child {
    justify-content: flex-start !important;
  }
}
.service .icon {
  background-color: #b1a3a3;
  border-radius: 50%;
  margin-right: 15px;
  padding: 15px;
  display: inline-block;
}
.service .icon:hover {
  background-color: #F37435;
}
.service .icon img {
  width: 45px;
  height: 45px;
}
.service .content {
  display: inline-block;
}
.service .content p {
  margin-bottom: 0px;
  line-height: 1.3;
}

.centerImg {
  border-radius: 20px;
  max-height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.cta_btn {
  background-color: #e6e6e6;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0 0 10px 10px;
  padding: 15px;
}

.video_gallery .thumbnail {
  position: relative;
}
.video_gallery .thumbnail:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50px 10px 0 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.video_gallery .thumbnail img {
  border-radius: 50px 10px 0 0;
  width: 100%;
}
.video_gallery .thumbnail .video_play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 0;
  width: 80px;
  height: 80px;
  line-height: 82px;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  border-radius: 50%;
  background: #F37435;
  margin: 0 auto;
  display: inline-block;
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.video_gallery .thumbnail .video_play_btn img {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  border-radius: 0px;
  margin-left: 5px;
}
.video_gallery .thumbnail .video_play_btn:before, .video_gallery .thumbnail .video_play_btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.video_gallery .thumbnail .video_play_btn:after {
  -webkit-animation: ripple 2s linear infinite !important;
  animation: ripple 2s linear infinite !important;
}
.video_gallery.multi .thumbnail:before {
  width: 93%;
  left: 12px;
}
.video_gallery.testimonial .thumbnail:before {
  border-radius: 8px 8px 0px 0px;
}
.video_gallery.testimonial .thumbnail img {
  border-radius: 8px 8px 0px 0px;
}
.video_gallery.testimonial .video_play_btn img {
  border-radius: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
.video_sec, .custom_sec {
  margin-bottom: 60px;
}
.video_sec h2, .custom_sec h2 {
  color: #F37435;
  font-size: 1.8em;
  font-family: "robotobold";
  text-transform: capitalize;
}

#subVideoSecCarousel {
  padding-bottom: 20px;
}
#subVideoSecCarousel .carousel-control-prev, #subVideoSecCarousel .carousel-control-next {
  display: none;
}
#subVideoSecCarousel .carousel-indicators {
  bottom: -50px;
}
#subVideoSecCarousel .carousel-indicators button {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #F37435;
}

.masonry-grid {
  columns: 2;
  column-gap: 1rem;
}
.masonry-grid .thumbnail {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.radius_img {
  border-radius: 40px;
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  object-position: bottom;
}

.flip-container {
  perspective: 1000px;
}
.flip-container .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.flip-container .flipper .front, .flip-container .flipper .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.flip-container .flipper .front img, .flip-container .flipper .back img {
  max-height: 500px;
}
.flip-container .flipper .front {
  z-index: 2;
  transform: rotateY(0deg);
}
.flip-container .flipper .back {
  transform: rotateY(180deg);
}
.flip-container.flipped .flipper {
  transform: rotateY(180deg);
}

.stf__parent {
  margin: 0 auto;
}

.stf__innerShadow, .stf__outerShadow, .stf__hardShadow, .stf__hardInnerShadow {
  bottom: 0;
  right: 0;
  height: 500px;
}

.pdf_header {
  display: flex;
  width: 100%;
}
.pdf_header h1 {
  margin-right: 30px;
}
.pdf_header button {
  margin: 0px 10px;
}
.pdf_header button.btn-close {
  padding: 0px 28px !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.fade .modal-dialog {
  transition: all 0.3s ease !important;
}

@media (min-width: 576px) {
  .modal .modal-dialog {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1490196078) !important;
  }
}
.top_padding {
  padding-top: 120px;
}
.top_padding .minus_margin {
  margin-top: -65px;
}
@media screen and (max-width: 576px) {
  .top_padding .minus_margin {
    margin-top: 30px;
  }
}
@media screen and (max-width: 576px) {
  .top_padding {
    padding-top: 0px;
  }
  .top_padding img {
    margin-top: 30px;
  }
}

.test_slider .slick-slide {
  padding: 15px;
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: #F37435 !important;
}

.testimonial_grid .thumbnail {
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}
.testimonial_grid .thumbnail .thumb_img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: contain;
  border: 1px solid #F37435;
}
.testimonial_grid .thumbnail .quote_icon {
  height: 34px;
  width: 34px;
  line-height: 30px;
  font-size: 13px;
  background-color: #F37435;
  border-radius: 50%;
  border: 4px solid #fff;
  display: block;
  text-align: center;
  position: absolute;
  bottom: -8px;
  right: -12px;
}
.testimonial_grid .thumbnail .quote_icon img {
  margin: 0 auto;
  padding: 4px;
}

.btn_group .btn {
  margin: 0px 10px;
}

.pdf_buttons {
  display: flex;
}

.slick-arrow {
  display: none !important;
}

.academics_tabs .nav-item a:hover {
  background-color: #F37435 !important;
  color: #fff !important;
}

.dropdown-item {
  font-size: 1em;
}

.photographs img {
  height: 300px;
  object-fit: cover;
  object-position: top;
}

.captcha_div {
  width: 100%;
  position: relative;
}
.captcha_div .captchaRefreshBtn {
  color: #000;
  border: none;
  position: absolute;
  right: 2px;
  background-color: #fff;
  font-size: 0;
  top: 4px;
  padding: 0;
  left: auto;
  z-index: 11;
}
.captcha_div .captchaRefreshBtn:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: url("../images/icons/sprite.png") no-repeat;
  background-size: 14px;
  right: 6px;
  top: 6px;
}

.scroll_btn {
  position: fixed;
  bottom: 60px;
  right: 20px;
  padding: 5px;
  background-color: #F37435;
  text-align: center;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.scroll_btn.float_btn {
  bottom: 60px;
  right: 80px;
}
.scroll_btn.float_btn:hover {
  background-color: #F37435 !important;
  color: #fff !important;
  border-color: #F37435 !important;
}
.scroll_btn img {
  width: 40px;
}

.cpa_title {
  font-size: 3em;
  line-height: inherit;
  font-style: italic;
}
@media screen and (max-width: 561px) {
  .cpa_title {
    font-size: 2em;
  }
}

.cpa_subtitle {
  color: rgb(148, 163, 184);
  opacity: 1;
  font-size: 1.15em;
  font-family: "robotoregular";
}

.cpa_main_img {
  max-height: 450px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.benifits_row {
  display: flex;
  align-items: center;
}

.center_text {
  max-width: 70%;
  margin: 0 auto;
}

.cpa_top .top_left img {
  width: 120px;
}
@media screen and (max-width: 561px) {
  .cpa_top .top_left img {
    width: 90px;
  }
}
.cpa_top .top_center {
  padding-left: 15px;
}
.cpa_top .social_icon ul li {
  background-color: #000;
  padding: 5px 8px;
  margin: 0px 5px;
  border-radius: 4px;
}
.cpa_top .social_icon ul li:hover {
  background-color: #F37435;
}
.cpa_top.sticky {
  position: fixed;
  background-color: #fff;
  color: #000;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #cecece;
  -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  animation: sticky 2s;
  -webkit-animation: sticky 2s;
  -moz-animation: sticky 2s;
  -ms-animation: sticky 2s;
  -o-animation: sticky 2s;
}

.feature_sec {
  width: 100%;
}
.feature_sec .feature_div {
  transition: all 0.4s ease;
  background-color: #F2F2F2;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin-bottom: 15px;
}
@media screen and (max-width: 769px) {
  .feature_sec .feature_div {
    justify-content: flex-start;
    text-align: left;
  }
}
.feature_sec .feature_div:hover {
  background-color: #F37435;
  color: #fff;
}
.feature_sec .feature_div:hover .icon {
  border: 2px solid #fff;
}
.feature_sec .feature_div .icon {
  background-color: #F37435;
  border-radius: 50px;
  padding: 15px;
  display: inline-block;
  border: 2px solid transparent;
}
.feature_sec .feature_div .icon img {
  width: 50px;
  height: auto;
}
.feature_sec .feature_div h6 {
  margin: 0px;
  font-size: 1.2em;
  align-self: center;
  margin-left: 10px;
}

.overlap_sec {
  margin-top: -130px;
}

.large_bottom {
  padding-bottom: 130px;
}

.cpa_strip {
  background-color: #F37435;
  color: #fff;
}
.cpa_strip .cpa_strip_content {
  padding: 15px;
  display: flex;
  width: 100%;
  vertical-align: middle;
  align-self: center;
}
@media screen and (min-width: 769px) {
  .cpa_strip .cpa_strip_content.mob {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .cpa_strip .cpa_strip_content.mob {
    display: block;
  }
}
@media screen and (max-width: 769px) {
  .cpa_strip .cpa_strip_content {
    display: none;
  }
  .cpa_strip .cpa_strip_content .content {
    display: block !important;
  }
  .cpa_strip .cpa_strip_content .content .first {
    padding: 5px;
  }
  .cpa_strip .cpa_strip_content .content .second, .cpa_strip .cpa_strip_content .content .first {
    width: 100%;
    justify-content: center;
  }
}
.cpa_strip .cpa_strip_content .first {
  border: 2px solid #fff;
  padding: 15px;
  width: 35%;
  align-self: center;
}
.cpa_strip .cpa_strip_content .second {
  width: 53%;
}
.cpa_strip .cpa_strip_content .second .btn {
  min-width: 50%;
}
.cpa_strip .cpa_strip_content .third {
  width: 12%;
  align-self: center;
}
.cpa_strip .title {
  color: #fff;
}

.ribbon {
  --r: .8em; /* control the ribbon shape */
  padding-inline: calc(var(--r) + 0.3em);
  line-height: 1.8;
  clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
  background: #fff; /* the main color */
  width: fit-content;
  display: block;
  margin: 0 auto;
  font-family: "robotobold";
}
.ribbon .date_strip {
  margin-bottom: 0;
  padding: 5px;
  color: #000;
}

.cpa_form_sec {
  border-bottom: 2px solid #000;
  background-color: #D6E0F0;
}
.cpa_form_sec .content_right {
  background-color: #D6E0F0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .cpa_form_sec .content_right {
    padding: 15px;
  }
}
.cpa_form_sec .content_right .cpa_form {
  background-color: #F37435;
  align-self: center;
  width: 100%;
  border: 8px solid #fff;
  padding: 30px;
}
.cpa_form_sec .content_right .cpa_form label {
  font-size: 1.4em;
  color: #fff;
}
.cpa_form_sec .content_right .cpa_form .form-control {
  height: 50px;
  margin-bottom: 15px;
}
.cpa_form_sec .content_left {
  padding: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .cpa_form_sec .content_left {
    padding: 15px;
  }
}
.cpa_form_sec .content_left .blackboard {
  background-color: #d5d9dc;
  border-radius: 40px;
  border: 8px solid #000;
  padding: 30px 60px;
  text-align: center;
}
@media screen and (max-width: 1025px) {
  .cpa_form_sec .content_left .blackboard {
    padding: 15px;
  }
}
@media screen and (max-width: 400px) {
  .cpa_form_sec .content_left .blackboard h1 {
    font-size: calc(1.375rem + 0.5vw);
  }
}
.cpa_form_sec .content_left .blackboard .cpa_text {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  padding: 5px;
  display: table;
  margin: 0 auto;
  text-align: center;
}

.abs_images .abs_img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background-color: #D6E0F0;
  position: absolute;
  border: 2px dashed #F37435;
  padding: 10px;
}
@media screen and (max-width: 1025px) {
  .abs_images .abs_img {
    width: 150px;
    height: 150px;
  }
}
.abs_images .abs_img.one {
  bottom: 10%;
}
@media screen and (max-width: 376px) {
  .abs_images .abs_img.one {
    left: 10px;
  }
}
.abs_images .abs_img.one .tooltip_text {
  background-color: #212529;
  padding: 5px;
  border-radius: 8px;
  position: absolute;
  left: 80%;
  bottom: 0;
  font-weight: 500;
  width: 200px;
  color: #fff;
}
.abs_images .abs_img.one .tooltip_text::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 0;
  top: 11%;
  border: 8px solid transparent;
  border-left: 0;
  border-right: 15px solid #000;
  transform: translate(calc(-100% + 3px), -50%);
}
.abs_images .abs_img.two {
  right: 8%;
  top: 8%;
}
@media screen and (max-width: 769px) {
  .abs_images .abs_img.two {
    right: 8%;
    top: 38%;
  }
}
@media screen and (max-width: 376px) {
  .abs_images .abs_img.two {
    right: 8%;
    top: 42%;
  }
}
.abs_images .abs_img.two .tooltip_text {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  position: absolute;
  left: -82%;
  bottom: 33%;
  font-weight: 500;
}
.abs_images .abs_img.two .tooltip_text::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  right: 0;
  top: 50%;
  border: 8px solid transparent;
  border-right: 0;
  border-left: 15px solid #fff;
  transform: translate(100%, -50%);
}
.abs_images .abs_img img {
  width: 100%;
  border-radius: 100%;
}

.cpa_form {
  border-radius: 8px;
}
.cpa_form.img_div {
  background-color: #fff;
  border-radius: 20px;
  border: 8px solid #fff;
  width: 100%;
}
.cpa_form.img_div img {
  height: 100%;
  border-radius: 8px;
  max-height: 595px;
  width: 100%;
}

.banner_sec .banner_slider.desk_view {
  display: block;
}
@media screen and (max-width: 769px) {
  .banner_sec .banner_slider.desk_view {
    display: none;
  }
}
.banner_sec .banner_slider.mob_view {
  display: none;
}
@media screen and (max-width: 769px) {
  .banner_sec .banner_slider.mob_view {
    display: block;
  }
}

@media screen and (max-width: 1025px) {
  .modal .title {
    font-size: 1.2em;
    justify-content: flex-end;
  }
  .pdf_header {
    display: block;
  }
  .pdf_buttons {
    display: block;
    margin-top: 10px;
  }
  .pdf_buttons button {
    margin: 0px 5px;
    font-size: 0.9em !important;
  }
}
@media screen and (max-width: 769px) {
  .mb-mid {
    margin-bottom: 15px !important;
  }
  .cpa_top .top_right {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .almuni_slider .slick-dots {
    margin-bottom: 50px !important;
  }
  .mb-small {
    margin-bottom: 15px !important;
  }
  .dropdown-item {
    font-size: 0.8em;
  }
  .top_nav .d-flex {
    display: block !important;
  }
  .top_nav .d-flex .top_left, .top_nav .d-flex .top_right {
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .top_nav .d-flex .top_left {
    margin-bottom: 10px !important;
  }
  .top_nav.cpa_top .d-flex {
    display: flex !important;
  }
  .top_nav.cpa_top .d-flex .top_left {
    width: 30% !important;
    justify-content: flex-start !important;
  }
  .top_nav.cpa_top .d-flex .top_center {
    width: 70% !important;
    justify-content: flex-start !important;
  }
  .slick-dots {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .banner_slider .carousel-item img {
    height: 500px;
    object-fit: inherit;
  }
}


