/*============= Color Variables  ================*/
$white:#fff;
$black:#000;
$purple:#7952b3;
$pink:#ff0080;
$blue:#0080ff;
$orange:#ffa500;
$brown:	#cc3333;
$gray:#edf0f2;
$darkOrange: #F37435;

$primaryColor:#F37435;
$secondaryColor:#D6E0F0;
